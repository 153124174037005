import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PcHomeView from '../views/PcHomeView.vue'
import MbHomeView from '../views/MbHomeView.vue'
import AboutUs from '../views/AboutView.vue'
import Agreement from '../views/AgreementView.vue'
import PrivacyPolicy from '../views/PrivacyPolicyView.vue'

const routes = [
  {
    path: '/xinglangle',
    name: 'home',
    component: HomeView
  },{
    path: '/xinglangle/home',
    name: 'pcHome',
    component: PcHomeView
  },{
    path: '/xinglangle/index',
    name: 'mbHome',
    component: MbHomeView
  },{
    path: '/xinglangle/agreement',
    name: 'agreement',
    component: Agreement
  },{
    path: '/xinglangle/privacyPolicy',
    name: 'privacyPolicy',
    component: PrivacyPolicy
  },{
    path: '/xinglangle/about',
    name: 'aboutUs',
    component: AboutUs
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
